import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AccreditationsSlotFragment } from 'common/src/generated/types';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { AccreditationsSlotFillingCell } from '../../accreditationsSlots/list/cells/accreditationsSlotFillingCell';
import { getAccreditationLimits } from '../../delegations/accreditations/getAccreditationLimits';
import { ActionsFunction, PanelFunction } from './accreditationCategories';
import { AccreditationSlots } from './accreditationSlots';

interface IAccreditationsProps {
    delegationSlots?: AccreditationsSlotFragment[];
    showFillingRate?: boolean;
    slots: AccreditationsSlotFragment[];

    actions?: ActionsFunction;
    panel?: PanelFunction;
}

export const Accreditations = ({
    actions,
    delegationSlots,
    panel,
    showFillingRate,
    slots
}: IAccreditationsProps) => {
    const slotsByAccreditation = React.useMemo(
        () =>
            sortBy(Object.values(groupBy(slots, (s) => s.accreditation.id)), (slots) =>
                slots[0].accreditation.name.toLowerCase()
            ),
        [slots]
    );

    return (
        <Flex
            css={{
                background: '$gray100',
                borderTop: '1px solid ^$gray200',
                padding: '$4'
            }}
            direction="column"
            gap="2"
        >
            {slotsByAccreditation.map((slots) => {
                const accreditation = slots[0].accreditation;

                return (
                    <Accreditation
                        key={accreditation.id}
                        accreditation={accreditation}
                        actions={actions}
                        delegationSlots={delegationSlots?.filter(
                            (daSlot) => daSlot.accreditation.id === accreditation.id
                        )}
                        panel={panel}
                        showFillingRate={showFillingRate}
                        slots={slots}
                    />
                );
            })}
        </Flex>
    );
};

interface IAccreditationProps {
    accreditation: AccreditationsSlotFragment['accreditation'];
    delegationSlots?: AccreditationsSlotFragment[];
    showFillingRate?: boolean;
    slots: AccreditationsSlotFragment[];

    actions?: ActionsFunction;
    panel?: PanelFunction;
}

const Accreditation = ({
    accreditation,
    actions,
    delegationSlots,
    panel,
    showFillingRate,
    slots
}: IAccreditationProps) => {
    const {
        params: { delegationId },
        translate
    } = useHeavent();
    const [isOpen, setIsOpen] = React.useState(true);
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);

    const hasSlots = accreditation.hasSlots;
    const {
        assignedResources,
        assignedWishedResources,
        limitIcon,
        limitIconWished,
        maxResources,
        percent,
        percentWished
    } = getAccreditationLimits(
        delegationSlots?.find((slot) => slot.id === accreditation.hiddenSlotId),
        slots.find((slot) => slot.id === accreditation.hiddenSlotId)
    );

    const canDisplayPanel = delegationId && panel && !hasSlots;

    return (
        <>
            <Flex
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    overflow: 'hidden'
                }}
                direction="column"
                onClick={() => {
                    if (canDisplayPanel) {
                        setIsPanelOpen(true);
                    }
                }}
            >
                <Flex
                    align="center"
                    css={{
                        cursor: hasSlots || canDisplayPanel ? 'pointer' : 'normal',
                        padding: '$4 $6',
                        userSelect: 'none'
                    }}
                    width={1}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <Flex align="center" css={{ flex: '1' }}>
                        {hasSlots && <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />}

                        <Spacer width="4" />

                        <Flex
                            align="center"
                            css={{
                                background: accreditation.color,
                                borderRadius: '12px',
                                color: getColorByBackgroundColor(accreditation.color),
                                fontSize: '$textMd',
                                height: '40px',
                                width: '40px'
                            }}
                            justify="center"
                        >
                            <I icon={fromIconVO(accreditation.icon)} />
                        </Flex>

                        <Spacer width="3" />

                        <Flex css={{ flex: '1' }} direction="column" gap="1">
                            <Box color="gray800" fontWeight="semiBold">
                                {accreditation.name}
                            </Box>

                            {hasSlots && (
                                <Box color="gray500">{translate('creneau', slots.length)}</Box>
                            )}
                        </Flex>
                    </Flex>

                    {!hasSlots && showFillingRate && (
                        <Box css={{ flex: '2' }}>
                            <AccreditationsSlotFillingCell
                                assignedResources={assignedWishedResources}
                                firstCellCss={{}}
                                limitIcon={limitIconWished}
                                maxResources={maxResources}
                                percent={percentWished}
                                title={translate('desired_accreditations')}
                            />
                        </Box>
                    )}

                    {!hasSlots && showFillingRate && (
                        <Box css={{ flex: '2' }}>
                            <AccreditationsSlotFillingCell
                                assignedResources={assignedResources}
                                firstCellCss={{}}
                                limitIcon={limitIcon}
                                maxResources={maxResources}
                                percent={percent}
                                title={translate('accr_ditations_39450')}
                            />
                        </Box>
                    )}

                    {!hasSlots && actions && actions(accreditation.hiddenSlotId)}
                </Flex>

                {hasSlots && isOpen ? (
                    <AccreditationSlots
                        actions={actions}
                        delegationSlots={delegationSlots}
                        panel={panel}
                        showFillingRate={showFillingRate}
                        slots={slots}
                    />
                ) : null}
            </Flex>

            {panel && isPanelOpen && panel(setIsPanelOpen, accreditation.hiddenSlotId)}
        </>
    );
};
