import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { AccreditationsSlotFragment, AccreditationsSlotId } from 'common/src/generated/types';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { Accreditations } from './accreditations';

export type PanelFunction = (
    setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>,
    slotId: AccreditationsSlotId
) => React.ReactNode;

export type ActionsFunction = (accreditationSlotId: AccreditationsSlotId) => React.ReactNode;

interface IAccreditationCategoriesProps {
    accreditationsSlots: AccreditationsSlotFragment[];
    delegationAccreditationsSlots?: AccreditationsSlotFragment[];
    showFillingRate?: boolean;

    actions?: ActionsFunction;
    panel?: PanelFunction;
}

export const AccreditationCategories = ({
    accreditationsSlots,
    delegationAccreditationsSlots,
    actions,
    panel,
    showFillingRate
}: IAccreditationCategoriesProps) => {
    const slotsByCategory = React.useMemo(
        () =>
            sortBy(
                Object.values(groupBy(accreditationsSlots, (as) => as.accreditationCategory.id)),
                (slots) => slots[0].accreditationCategory.name.toLowerCase()
            ),
        [accreditationsSlots]
    );

    return (
        <Flex direction="column" gap="4" width={1}>
            {slotsByCategory.map((slots) => (
                <AccreditationCategory
                    key={slots[0].accreditationCategory.id}
                    actions={actions}
                    category={slots[0].accreditationCategory}
                    delegationSlots={delegationAccreditationsSlots?.filter(
                        (daSlot) =>
                            daSlot.accreditationCategory.id === slots[0].accreditationCategory.id
                    )}
                    panel={panel}
                    showFillingRate={showFillingRate}
                    slots={slots}
                />
            ))}
        </Flex>
    );
};

interface IAccreditationCategoryProps {
    category: AccreditationsSlotFragment['accreditationCategory'];
    delegationSlots?: AccreditationsSlotFragment[];
    showFillingRate?: boolean;
    slots: AccreditationsSlotFragment[];

    actions?: ActionsFunction;
    panel?: PanelFunction;
}

const AccreditationCategory = ({
    actions,
    category,
    delegationSlots,
    panel,
    showFillingRate,
    slots
}: IAccreditationCategoryProps) => {
    const [isOpen, setIsOpen] = React.useState(true);

    return (
        <Flex
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                overflow: 'hidden'
            }}
            direction="column"
        >
            <Flex
                align="center"
                css={{ background: 'white', cursor: 'pointer', padding: '$4', userSelect: 'none' }}
                gap="1"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <Flex
                    align="center"
                    css={{ fontSize: '$textXs' }}
                    height={32}
                    justify="center"
                    width={32}
                >
                    <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                </Flex>

                <Box font="gray800 textMd medium">{category.name}</Box>
            </Flex>

            {isOpen && (
                <Accreditations
                    actions={actions}
                    delegationSlots={delegationSlots}
                    panel={panel}
                    showFillingRate={showFillingRate}
                    slots={slots}
                />
            )}
        </Flex>
    );
};
