import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditationsSlotFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { fullName } from 'common/src/vo/accreditationSlot';
import { compact, sortBy } from 'lodash-es';
import * as React from 'react';
import { AccreditationsSlotFillingCell } from '../../accreditationsSlots/list/cells/accreditationsSlotFillingCell';
import { getAccreditationLimits } from '../../delegations/accreditations/getAccreditationLimits';
import { useHeavent } from '../../hooks/useHeavent';
import { ActionsFunction, PanelFunction } from './accreditationCategories';

interface IAccreditationSlotsProps {
    delegationSlots?: AccreditationsSlotFragment[];
    showFillingRate?: boolean;
    slots: AccreditationsSlotFragment[];

    actions?: ActionsFunction;
    panel?: PanelFunction;
}

export const AccreditationSlots = ({
    actions,
    delegationSlots,
    panel,
    showFillingRate,
    slots
}: IAccreditationSlotsProps) => {
    const sortedSlots = React.useMemo(
        () => sortBy(slots, (s) => compact([s.date?.toMillis(), s.name.toLowerCase()])),
        [slots]
    );

    return (
        <Flex direction="column">
            {sortedSlots.map((slot) => (
                <AccreditationSlot
                    key={slot.id}
                    actions={actions}
                    delegationSlot={delegationSlots?.find((daSlot) => daSlot.id === slot.id)}
                    panel={panel}
                    showFillingRate={showFillingRate}
                    slot={slot}
                />
            ))}
        </Flex>
    );
};

interface IAccreditationSlotProps {
    delegationSlot?: AccreditationsSlotFragment;
    showFillingRate?: boolean;
    slot: AccreditationsSlotFragment;

    actions?: ActionsFunction;
    panel?: PanelFunction;
}

const AccreditationSlot = ({
    actions,
    delegationSlot,
    panel,
    showFillingRate,
    slot
}: IAccreditationSlotProps) => {
    const {
        params: { delegationId },
        translate
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);
    const {
        assignedResources,
        assignedWishedResources,
        limitIcon,
        limitIconWished,
        maxResources,
        percent,
        percentWished
    } = getAccreditationLimits(delegationSlot, slot);

    const canDisplayPanel = delegationId && panel;

    return (
        <>
            <Flex
                css={{
                    borderBlockStart: '1px solid $gray200',
                    cursor: canDisplayPanel ? 'pointer' : 'inherit',
                    padding: '$4 $6',
                    inlineSize: '100%',
                    '&:hover ': {}
                }}
                onClick={() => {
                    if (delegationId) {
                        setIsPanelOpen(true);
                    }
                }}
            >
                <Box
                    css={{
                        color: '$gray800',
                        flex: '1',
                        fontSize: '$2',
                        inlineSize: '20%',
                        marginBlock: 'auto',
                        textTransform: 'capitalize'
                    }}
                    fontWeight="medium"
                >
                    {fullName(dateTimeService, slot, '', {
                        format: LocaleFormats.DateOnly.WeekdayLongMonthLong
                    })}
                </Box>

                {showFillingRate && (
                    <Box css={{ flex: '2' }}>
                        <AccreditationsSlotFillingCell
                            assignedResources={assignedWishedResources}
                            firstCellCss={{}}
                            limitIcon={limitIconWished}
                            maxResources={maxResources}
                            percent={percentWished}
                            title={translate('desired_accreditations')}
                        />
                    </Box>
                )}

                {showFillingRate && (
                    <Box css={{ flex: '2' }}>
                        <AccreditationsSlotFillingCell
                            assignedResources={assignedResources}
                            firstCellCss={{}}
                            limitIcon={limitIcon}
                            maxResources={maxResources}
                            percent={percent}
                            title={translate('accr_ditations_39450')}
                        />
                    </Box>
                )}

                {actions && (
                    <Box
                        css={{
                            cursor: 'pointer'
                        }}
                        width="1"
                    >
                        {actions(slot.id)}
                    </Box>
                )}
            </Flex>

            {panel && isPanelOpen && panel(setIsPanelOpen, slot.id)}
        </>
    );
};
